import { useEffect, useState } from "react";
import Layout from "../components/Layout";
import InputPages from "../pages/InputPages";

function Analyze() {
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [currentTitle, setCurrentTitle] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    if (currentStep === 0) setCurrentTitle("로그인");
    if (currentStep === 1) setCurrentTitle("사업장 정보 입력");
    if (currentStep === 2) setCurrentTitle("건물 정보 입력");
    if (currentStep === 3) setCurrentTitle("생산공정 설명");
    if (currentStep === 4) setCurrentTitle("변압기 상태 입력");
    if (currentStep === 5) setCurrentTitle("소방시설 상태 입력");
    if (currentStep === 6) setCurrentTitle("방재관리 상태 입력");
    if (currentStep === 7) setCurrentTitle("기타위험정보 입력");
  }, [currentStep]);

  return (
    <Layout currentStep={currentStep} title={currentTitle}>
      <InputPages currentStep={currentStep} setCurrentStep={setCurrentStep} />
    </Layout>
  );
}

export default Analyze;
