import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Layout from "../components/Layout";

function Result() {
  const location = useLocation();
  const [results, setResults] = useState<
    { name: string; score: number; average: number }[]
  >([]);
  let currentState = location.state;

  useEffect(() => {
    if (currentState) {
      const {
        buildingScore,
        industryScore,
        disasterScore,
        fireScore,
        nearbyFacilitiesScore,
      } = currentState;

      setResults([
        {
          name: "건물",
          score: buildingScore,
          average: 5,
        },
        {
          name: "공정",
          score: industryScore,
          average: 6,
        },
        {
          name: "소방시설",
          score: fireScore,
          average: 6,
        },
        {
          name: "방재관리",
          score: disasterScore,
          average: 5,
        },
        {
          name: "기타위험",
          score: nearbyFacilitiesScore,
          average: 4,
        },
      ]);
    }
  }, [currentState]);

  return (
    <Layout title="결과 확인">
      <div className="space-y-6">
        <div className="flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        구분
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        평가결과
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:pr-6"
                      >
                        평균
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {results.length > 0 &&
                      results.map((result) => (
                        <tr key={result.name}>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                            {result.name}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {result.score}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:pr-6">
                            {result.average}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div>
          <Link
            to="/"
            className="flex w-full justify-center rounded-md bg-main-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-main-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-main-600"
          >
            홈으로
          </Link>
        </div>
      </div>
    </Layout>
  );
}

export default Result;
