import { sortBy } from "lodash";

export const industrySpecificRiskBasicPoints = sortBy(
  [
    { industryCode: "1", industryName: "주택 및 다가구", basicPoint: 1.25 },
    { industryCode: "2", industryName: "아파트 ", basicPoint: 1 },
    { industryCode: "3", industryName: "식당", basicPoint: 1.75 },
    { industryCode: "4", industryName: "근린생활시설(상가)", basicPoint: 2 },
    { industryCode: "5", industryName: "종교시설", basicPoint: 1.75 },
    { industryCode: "6", industryName: "사회복지시설", basicPoint: 1.25 },
    { industryCode: "7", industryName: "병원", basicPoint: 3 },
    { industryCode: "8", industryName: "학교 학원", basicPoint: 1.5 },
    { industryCode: "9", industryName: "대학 연구소, 연구소", basicPoint: 4 },
    { industryCode: "10", industryName: "도서관", basicPoint: 2 },
    { industryCode: "11", industryName: "실내 체육 시설", basicPoint: 2 },
    { industryCode: "12", industryName: "사무실, 오피스텔", basicPoint: 1.5 },
    { industryCode: "13", industryName: "호텔 및 숙박시설", basicPoint: 3 },
    { industryCode: "14", industryName: "시장", basicPoint: 3.25 },
    { industryCode: "15", industryName: "대형판매시설", basicPoint: 2.5 },
    { industryCode: "16", industryName: "주점", basicPoint: 2 },
    { industryCode: "17", industryName: "목욕장(찜질방 등)", basicPoint: 1.5 },
    {
      industryCode: "18",
      industryName: "공연장, 예식장, 전시장, 동식물원 ",
      basicPoint: 1.5,
    },
    { industryCode: "19", industryName: "모델하우스", basicPoint: 4.75 },
    { industryCode: "20", industryName: "창고", basicPoint: 5.5 },
    {
      industryCode: "21",
      industryName: "주유소, 가스판매, 위험물 취급소",
      basicPoint: 2.5,
    },
    { industryCode: "22", industryName: "터미널, 공항, 역사", basicPoint: 2.5 },
    { industryCode: "23", industryName: "차량 관련 시설", basicPoint: 3 },
    {
      industryCode: "24",
      industryName: "축사, 부화장, 동물 사육",
      basicPoint: 3,
    },
    {
      industryCode: "25",
      industryName: "고물상(수집 및 분류만)",
      basicPoint: 2.5,
    },
    { industryCode: "26", industryName: "폐기물재활용시설", basicPoint: 5 },
    { industryCode: "27", industryName: "식물제배시설", basicPoint: 2.5 },
    { industryCode: "28", industryName: "교도소 및 교정시설", basicPoint: 2.5 },
    { industryCode: "29", industryName: "방송국", basicPoint: 1.25 },
    { industryCode: "30", industryName: "데이터센터", basicPoint: 1.25 },
    {
      industryCode: "31",
      industryName: "촬영소, 영화현상소",
      basicPoint: 2.25,
    },
    { industryCode: "32", industryName: "장례식장", basicPoint: 1.25 },
    { industryCode: "33", industryName: "광산", basicPoint: 2.75 },
    { industryCode: "34", industryName: "광석가공", basicPoint: 3.5 },
    {
      industryCode: "35",
      industryName: "비철금속 정련 및 가공",
      basicPoint: 3.5,
    },
    { industryCode: "36", industryName: "제철소", basicPoint: 2.75 },
    { industryCode: "37", industryName: "철강가공", basicPoint: 2.25 },
    { industryCode: "38", industryName: "금속기구기계제조", basicPoint: 2.5 },
    {
      industryCode: "39",
      industryName: "전기전자기계기구제조",
      basicPoint: 2.75,
    },
    { industryCode: "40", industryName: "반도체, LCD등의 FAB", basicPoint: 4 },
    {
      industryCode: "41",
      industryName: "모바일, 컴퓨터 제조",
      basicPoint: 3.5,
    },
    { industryCode: "42", industryName: "가전제품제조", basicPoint: 2.75 },
    { industryCode: "43", industryName: "전선제조", basicPoint: 3 },
    { industryCode: "44", industryName: "전지제조", basicPoint: 4 },
    { industryCode: "45", industryName: "조명기구제조", basicPoint: 2.5 },
    { industryCode: "46", industryName: "차량제조,정비", basicPoint: 2.5 },
    { industryCode: "47", industryName: "조선(강철선)", basicPoint: 2.5 },
    {
      industryCode: "48",
      industryName: "항공기, 로켓, 헬리콥터",
      basicPoint: 1,
    },
    { industryCode: "49", industryName: "광학기구", basicPoint: 3.25 },
    { industryCode: "50", industryName: "시계제조", basicPoint: 2 },
    { industryCode: "51", industryName: "드럼통 및 가스용기", basicPoint: 2.5 },
    { industryCode: "52", industryName: "기타 금속가공업종", basicPoint: 1.5 },
    {
      industryCode: "53",
      industryName: "금속박, 금속가루 제조",
      basicPoint: 1.5,
    },
    { industryCode: "54", industryName: "가스/오일 발전", basicPoint: 3.5 },
    { industryCode: "55", industryName: "석탄화력", basicPoint: 4.5 },
    { industryCode: "56", industryName: "수력발전", basicPoint: 2.5 },
    { industryCode: "57", industryName: "연료전지발전", basicPoint: 4.5 },
    {
      industryCode: "58",
      industryName: "지열,태양광,풍력 발전",
      basicPoint: 3.5,
    },
    { industryCode: "59", industryName: "폐기물소각발전", basicPoint: 6 },
    { industryCode: "60", industryName: "담수화 공장", basicPoint: 3.5 },
    { industryCode: "61", industryName: "변전소/송배전설비", basicPoint: 3.5 },
    { industryCode: "62", industryName: "열발생소, 열공급소", basicPoint: 3.5 },
    { industryCode: "63", industryName: "산업용가스제조", basicPoint: 6 },
    { industryCode: "64", industryName: "LPG, LNG 공장", basicPoint: 6 },
    { industryCode: "65", industryName: "폭약(성냥,폭죽)공장", basicPoint: 5 },
    { industryCode: "66", industryName: "의약품제조", basicPoint: 4 },
    {
      industryCode: "67",
      industryName: "동식물성기름, 유지, 양초 제조",
      basicPoint: 4,
    },
    { industryCode: "68", industryName: "고무제품제조", basicPoint: 5 },
    { industryCode: "69", industryName: "도료, 잉크, 안료", basicPoint: 3 },
    {
      industryCode: "70",
      industryName: "화학섬유, 합성섬유제조",
      basicPoint: 4,
    },
    {
      industryCode: "71",
      industryName: "플라스틱 제품제조(비발포)",
      basicPoint: 4,
    },
    {
      industryCode: "72",
      industryName: "석유화학(Petrochemical)",
      basicPoint: 5,
    },
    {
      industryCode: "73",
      industryName: "플라스틱 필름제조/가공",
      basicPoint: 4.5,
    },
    {
      industryCode: "74",
      industryName: "플라스틱 제품제조(발포)",
      basicPoint: 7,
    },
    {
      industryCode: "75",
      industryName: "플라스틱 절단 조립만의 가공",
      basicPoint: 4,
    },
    { industryCode: "76", industryName: "비료제조", basicPoint: 4 },
    {
      industryCode: "77",
      industryName: "탄소제품제조 및 가공",
      basicPoint: 1.75,
    },
    { industryCode: "78", industryName: "펄프 및 제지", basicPoint: 2.5 },
    { industryCode: "79", industryName: "도자기 및 타일", basicPoint: 2 },
    { industryCode: "80", industryName: "기타 요업제품제조", basicPoint: 1.25 },
    { industryCode: "81", industryName: "유리제조", basicPoint: 3 },
    {
      industryCode: "82",
      industryName: "유리섬유 및 가공품",
      basicPoint: 1.75,
    },
    { industryCode: "83", industryName: "인쇄", basicPoint: 2.5 },
    { industryCode: "84", industryName: "유리가공품제조", basicPoint: 1.5 },
    { industryCode: "85", industryName: "레미콘, 시멘트제조", basicPoint: 2 },
    { industryCode: "86", industryName: "시멘트 제품제조", basicPoint: 1 },
    { industryCode: "87", industryName: "아스팔트콘크리트제조", basicPoint: 2 },
    { industryCode: "88", industryName: "석면제조 및 가공", basicPoint: 1.5 },
    { industryCode: "89", industryName: "암면제조및 가공", basicPoint: 1 },
    {
      industryCode: "90",
      industryName: "토석가공(보석제외)",
      basicPoint: 1.25,
    },
    { industryCode: "91", industryName: "보석가공", basicPoint: 1.5 },
    { industryCode: "92", industryName: "석회제조", basicPoint: 1 },
    { industryCode: "93", industryName: "석고제조", basicPoint: 2.25 },
    { industryCode: "94", industryName: "인조석제조", basicPoint: 3 },
    {
      industryCode: "95",
      industryName: "실리콘잉곳, 사파이어제조",
      basicPoint: 4,
    },
    { industryCode: "96", industryName: "방적", basicPoint: 5.5 },
    { industryCode: "97", industryName: "제직", basicPoint: 5 },
    { industryCode: "98", industryName: "제사 및 고치건조", basicPoint: 3.4 },
    { industryCode: "99", industryName: "염색 표백 및 가공", basicPoint: 3 },
    { industryCode: "100", industryName: "직물 재단 및 재봉", basicPoint: 4 },
    {
      industryCode: "101",
      industryName: "솜/털 제조, 재생 정리",
      basicPoint: 4,
    },
    { industryCode: "102", industryName: "그물 및 노끈 제조", basicPoint: 2.5 },
    { industryCode: "103", industryName: "펠트 및 부직포 제조", basicPoint: 6 },
    { industryCode: "104", industryName: "합판제조", basicPoint: 5 },
    { industryCode: "105", industryName: "제재", basicPoint: 4 },
    { industryCode: "106", industryName: "목공 및 목재가공", basicPoint: 3.5 },
    { industryCode: "107", industryName: "제분 및 전분", basicPoint: 3 },
    { industryCode: "108", industryName: "정곡", basicPoint: 4 },
    { industryCode: "109", industryName: "주류제조", basicPoint: 2 },
    {
      industryCode: "110",
      industryName: "장류 및 조미료제조",
      basicPoint: 1.5,
    },
    { industryCode: "111", industryName: "포도당, 설탕제조", basicPoint: 3 },
    { industryCode: "112", industryName: "스낵류 제조", basicPoint: 2.24 },
    { industryCode: "113", industryName: "축,수산물 가공", basicPoint: 2.25 },
    { industryCode: "114", industryName: "도축", basicPoint: 1.5 },
    { industryCode: "115", industryName: "음료수제조", basicPoint: 1.5 },
    { industryCode: "116", industryName: "기타식료품 가공", basicPoint: 2.25 },
    { industryCode: "117", industryName: "종이제품제조", basicPoint: 3.5 },
    { industryCode: "118", industryName: "기타 지공업", basicPoint: 3 },
    { industryCode: "119", industryName: "정유", basicPoint: 6 },
    {
      industryCode: "120",
      industryName: "가죽 및 가죽제품가공",
      basicPoint: 4,
    },
    { industryCode: "121", industryName: "사료제조", basicPoint: 5 },
    {
      industryCode: "122",
      industryName: "짚(줄기) 가공및 제품제조",
      basicPoint: 5,
    },
    { industryCode: "123", industryName: "식물 및 약초류 건조", basicPoint: 2 },
    { industryCode: "124", industryName: "도장(페인팅)", basicPoint: 5 },
    { industryCode: "125", industryName: "도금", basicPoint: 5 },
    { industryCode: "126", industryName: "폐기물 소각장", basicPoint: 5.5 },
    { industryCode: "127", industryName: "그밖의 공장", basicPoint: 4 },
  ],
  "industryName"
);
