import { useEffect, useRef, useState } from "react";
import { useDaumPostcodePopup } from "react-daum-postcode";
import { KAKAOMAP_KEY } from "../constants";
import { InputPageProps } from "../interfaces";
import { InputField } from "./inputs";

function Page1({
  register,
  setValue,
  getValues,
  readOnly = false,
}: InputPageProps) {
  const open = useDaumPostcodePopup();
  const mapRef = useRef<HTMLDivElement>(null);
  const [mapLoaded, setMapLoaded] = useState<boolean>(false);
  const [showMap, setShowMap] = useState<boolean>(false);
  const [kakaoMethod, setKakaoMethod] = useState<{
    kakao: any;
    mapContainer: HTMLDivElement | null;
    map: any;
    geocoder: any;
    marker: any;
  }>();

  useEffect(() => {
    const $script = document.createElement("script");
    $script.src = `//dapi.kakao.com/v2/maps/sdk.js?appkey=${KAKAOMAP_KEY}&libraries=services&autoload=false`;
    $script.addEventListener("load", () => setMapLoaded(true));
    document.head.appendChild($script);
  }, []);

  useEffect(() => {
    if (mapLoaded) {
      const kakao = (window as any).kakao;
      kakao.maps.load(() => {
        const mapContainer = mapRef.current; // 지도를 표시할 div
        const mapOption = {
          center: new kakao.maps.LatLng(37.537187, 127.005476), // 지도의 중심좌표
          level: 5, // 지도의 확대 레벨
        };

        const map = new kakao.maps.Map(mapContainer, mapOption); // 지도를 미리 생성
        const geocoder = new kakao.maps.services.Geocoder(); // 주소-좌표 변환 객체를 생성
        const marker = new kakao.maps.Marker({
          position: new kakao.maps.LatLng(37.537187, 127.005476),
          map: map,
        }); // 마커를 미리 생성

        setKakaoMethod({ kakao, mapContainer, map, geocoder, marker });
      });
    }
  }, [mapLoaded]);

  useEffect(() => {
    if (kakaoMethod && getValues("businessAddress")) {
      handleComplete({ address: getValues("businessAddress") });
    }
  }, [kakaoMethod]);

  const handleComplete = (data: any) => {
    const { address, zonecode = null } = data;
    const { kakao, mapContainer, map, geocoder, marker }: any = kakaoMethod;

    if (kakao && mapContainer && map && geocoder && marker) {
      setValue("businessAddress", address);
      if (zonecode) {
        setValue("businessZipCode", zonecode);
      }

      geocoder.addressSearch(address, function (results: any, status: any) {
        if (status === kakao.maps.services.Status.OK) {
          // 정상적으로 검색이 완료됐으면
          var result = results[0]; //첫번째 결과의 값을 활용
          var coords = new kakao.maps.LatLng(result.y, result.x); // 해당 주소에 대한 좌표를 받아서
          setShowMap(true); // 지도를 보여준다.
          mapContainer.style.display = "block";
          map.relayout();
          map.setCenter(coords); // 지도 중심 변경
          marker.setPosition(coords); // 마커를 결과값으로 받은 위치로 옮긴다.
        }
      }); // 주소로 상세 정보를 검색
    }
  };

  const handleClick = () => {
    open({ onComplete: handleComplete });
  };

  return (
    <div className="space-y-12">
      <div className="border-b border-gray-900/10 pb-12">
        <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          <div className="sm:col-span-4">
            <InputField
              readOnly={readOnly}
              register={register}
              fieldName="businessName"
            />
          </div>
          <div className="sm:col-span-4 sm:col-start-1">
            <InputField
              register={register}
              fieldName="businessAddress"
              readOnly={true}
              placeholder="우편번호 찾기를 통해 자동으로 입력됩니다."
            />
          </div>
          {!readOnly && (
            <div className="sm:col-span-2 flex items-end">
              <button
                type="button"
                className="rounded-md bg-main-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-main-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-main-600"
                onClick={() => {
                  handleClick();
                }}
              >
                우편번호 찾기
              </button>
            </div>
          )}
          <div className="sm:col-span-4 sm:col-start-1">
            <InputField
              readOnly={readOnly}
              register={register}
              fieldName="businessAddress2"
            />
          </div>
          <div className="sm:col-span-2">
            <InputField
              register={register}
              fieldName="businessZipCode"
              readOnly={true}
            />
          </div>
          <div className="col-span-full">
            <label
              htmlFor="cover-photo"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              지도화면
            </label>
            {!showMap && (
              <div className="mt-2 flex justify-center items-center rounded-lg border border-dashed border-gray-900/25 px-6 h-56">
                <div className="text-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="mx-auto h-8 w-8 text-gray-300"
                  >
                    <path
                      fillRule="evenodd"
                      d="M11.47 4.72a.75.75 0 011.06 0l7.5 7.5a.75.75 0 11-1.06 1.06L12 6.31l-6.97 6.97a.75.75 0 01-1.06-1.06l7.5-7.5zm.53 7.59l-6.97 6.97a.75.75 0 01-1.06-1.06l7.5-7.5a.75.75 0 011.06 0l7.5 7.5a.75.75 0 11-1.06 1.06L12 12.31z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <div className="mt-4 flex text-sm leading-6 text-gray-600">
                    <p>주소를 입력해주세요.</p>
                  </div>
                </div>
              </div>
            )}
            <div
              ref={mapRef}
              className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 h-56 hidden"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Page1;
