import { InputPageProps } from "../interfaces";
import { InputField, SelectField } from "./inputs";

const selectOptions = {
  smokingManagementAtTheBusinessPremises: [
    "nonOperating",
    "smokingAreaDesignatedInsideOutside",
    "smokingAreaDesignatedOutside",
    "noSmokingAtBusinessPremises",
  ],
  disasterPreparednessDedicatedStaff: [
    "none",
    "concurrentDuty",
    "dedicatedPersonnel1",
    "teamUnit",
  ],
  permitSystem: ["operating", "nonOperating"],
};

function Page6({ register, getValues, readOnly = false }: InputPageProps) {
  return (
    <div className="space-y-12">
      <div className="border-b border-gray-900/10 pb-12">
        <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          <div className="sm:col-span-3">
            <InputField
              readOnly={readOnly}
              register={register}
              fieldName="staffCount"
              type="number"
            />
          </div>
          <div className="sm:col-span-3">
            <InputField
              readOnly={readOnly}
              register={register}
              fieldName="nightShiftPersonnel"
              type="number"
            />
          </div>
          <div className="sm:col-span-3">
            <SelectField
              readOnly={readOnly}
              register={register}
              getValues={getValues}
              fieldName="disasterPreparednessDedicatedStaff"
              options={selectOptions["disasterPreparednessDedicatedStaff"]}
            />
          </div>
          <div className="sm:col-span-3">
            <InputField
              readOnly={readOnly}
              register={register}
              fieldName="securityStaffCount"
              type="number"
            />
          </div>
          <div className="sm:col-span-3">
            <InputField
              readOnly={readOnly}
              register={register}
              fieldName="safetyEducationFrequency"
              placeholder="연 00회"
              type="number"
            />
          </div>
          <div className="sm:col-span-3">
            <InputField
              readOnly={readOnly}
              register={register}
              fieldName="fireDrillFrequency"
              placeholder="연 00회"
              type="number"
            />
          </div>
          <div className="sm:col-span-3">
            <SelectField
              readOnly={readOnly}
              register={register}
              getValues={getValues}
              fieldName="permitSystem"
              options={selectOptions["permitSystem"]}
            />
          </div>

          <div className="sm:col-span-3">
            <SelectField
              readOnly={readOnly}
              register={register}
              getValues={getValues}
              fieldName="smokingManagementAtTheBusinessPremises"
              options={selectOptions["smokingManagementAtTheBusinessPremises"]}
            />
          </div>
          <div className="sm:col-span-3">
            <InputField
              readOnly={readOnly}
              register={register}
              fieldName="distanceToFireStation"
              placeholder="단위: km"
              type="number"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Page6;
