import { industrySpecificRiskBasicPoints } from "../constants/industrySpecificRiskBasicPoints";
import { InputPageProps } from "../interfaces";
import { InputField, TextAreaField } from "./inputs";

function Page3({ register, getValues, readOnly = false }: InputPageProps) {
  return (
    <div className="space-y-12">
      <div className="border-b border-gray-900/10 pb-12">
        <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          <div className="sm:col-span-3">
            <InputField
              readOnly={readOnly}
              register={register}
              fieldName="industry"
              list
            />
            <datalist id="industry">
              {industrySpecificRiskBasicPoints.map(
                ({ industryCode, industryName, basicPoint }) => (
                  <option key={industryCode} value={industryName} />
                )
              )}
            </datalist>
          </div>
          <div className="sm:col-span-3">
            <InputField
              readOnly={readOnly}
              register={register}
              fieldName="productManufactured"
            />
          </div>

          <div className="col-span-full">
            <TextAreaField
              readOnly={readOnly}
              register={register}
              fieldName="productionProcessDescription"
              placeholder="생산 공정을 원료부터 제품까지의 단계별로 기술해 주십시요.
              특이 가열하는 공정은 열원과 가열하는 온도를 기술하고,
              화재위험이 높은 위험물을 취급하는 공정은 해당 공정의 안전조치를
              함께 기술해 주십시요"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Page3;
