import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./pages/Login";
import Result from "./pages/Result";
import Analyze from "./pages/Analyze";
import ResultList from "./pages/ResultList";
import ResultDetail from "./pages/ResultDetail";
import NoPage from "./pages/NoPage";
import { RecoilRoot } from "recoil";

function App() {
  return (
    <RecoilRoot>
      <BrowserRouter>
        <Routes>
          <Route path="/">
            <Route index element={<Login />} />
            <Route path="analyze" element={<Analyze />} />
            <Route path="result" element={<Result />} />
            <Route path="results" element={<ResultList />} />
            <Route path="results/show" element={<ResultDetail />} />
            <Route path="*" element={<NoPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </RecoilRoot>
  );
}

export default App;
