import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Page1, Page2, Page3, Page4, Page5, Page6, Page7 } from "../components";
import { InputOptions } from "../interfaces";
import {
  buildingLogic,
  disasterLogic,
  fireLogic,
  industryLogic,
  nearbyFacilitiesLogic,
} from "../utils";
import { dummyData } from "../constants";
import { industrySpecificRiskBasicPoints } from "../constants/industrySpecificRiskBasicPoints";
import Swal from "sweetalert2";
import { supabase } from "../supabaseClient";

interface BaseProps {
  currentStep: number;
  setCurrentStep: any;
}

const LASTSTEP = 7;

function InputPages({ currentStep, setCurrentStep }: BaseProps) {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<InputOptions>();

  const onSubmit = async (data: any) => {
    if (currentStep >= LASTSTEP) {
      const buildingScore = buildingLogic(data);
      const industryScore = industryLogic(data);
      const disasterScore = disasterLogic(data);
      const fireScore = fireLogic(data);
      const nearbyFacilitiesScore = nearbyFacilitiesLogic(data);

      try {
        await supabase
          .from("result")
          .insert({
            ...data,
            totalScore: {
              buildingScore,
              industryScore,
              disasterScore,
              fireScore,
              nearbyFacilitiesScore,
            },
          })
          .select()
          .single();

        navigate("/result", {
          state: {
            buildingScore,
            industryScore,
            disasterScore,
            fireScore,
            nearbyFacilitiesScore,
          },
        });
      } catch (error) {
        alert("[Error: DB] 문제가 발생했습니다. 관리자에게 문의해주세요.");
      }
    } else if (currentStep === 3) {
      const selectedIndustry = industrySpecificRiskBasicPoints.find(
        (e) => e.industryName === getValues("industry")
      );
      if (selectedIndustry) {
        setValue("industryBasicPoint", selectedIndustry?.basicPoint);
        setCurrentStep((prev: number) => prev + 1);
      } else {
        Swal.fire({
          icon: "error",
          text: "올바른 업종을 선택해주세요.",
          heightAuto: false,
          confirmButtonColor: "rgb(0, 139, 255)",
        });
      }
    } else {
      setCurrentStep((prev: number) => prev + 1);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <>
        {currentStep === 1 && (
          <Page1
            register={register}
            setValue={setValue}
            getValues={getValues}
          />
        )}
        {currentStep === 2 && (
          <Page2
            register={register}
            setValue={setValue}
            getValues={getValues}
          />
        )}
        {currentStep === 3 && (
          <Page3 register={register} getValues={getValues} />
        )}
        {currentStep === 4 && (
          <Page4 register={register} getValues={getValues} />
        )}
        {currentStep === 5 && (
          <Page5 register={register} getValues={getValues} />
        )}
        {currentStep === 6 && (
          <Page6 register={register} getValues={getValues} />
        )}
        {currentStep >= LASTSTEP && (
          <Page7 register={register} getValues={getValues} />
        )}
      </>
      <div className="mt-6 flex items-center justify-end gap-x-6">
        {currentStep > 2 && (
          <button
            type="button"
            className="text-sm font-semibold leading-6 text-gray-900"
            onClick={() => {
              setCurrentStep((prev: number) => prev - 1);
            }}
          >
            이전
          </button>
        )}
        <button
          type="submit"
          className="rounded-md bg-main-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-main-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-main-600"
        >
          {currentStep >= LASTSTEP ? "결과 확인" : "다음"}
        </button>
      </div>
    </form>
  );
}

export default InputPages;
