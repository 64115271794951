import { useEffect, useState } from "react"
import { ErrorMessage, StreamSpecialCase, StreamStatus } from "../interfaces/stream"
import { decodeUnicodeString } from "../utils"
import { supabase } from "../supabaseClient"

const useMessage = (resultId: string) => {
  const [isSocketConnected, setIsSocketConnected] = useState<boolean>(false)
  const [isFinished, setIsFinished] = useState<boolean>(false)
  const [socketConnection, setSocketConnection] = useState<WebSocket>()
  const [streamStatus, setStreamStatus] = useState<StreamStatus>(StreamStatus.Ready)
  const [streamMessage, setStreamMessage] = useState<string>('')

  const versionID = 'bec69589-a6f4-4329-bdff-4ec8c9fab7aa'
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const createChannel = async () => {
    const socketConn = new WebSocket(`wss://socket.getlinq.com/production?version_id=${versionID}`)
    socketConn.addEventListener('open', () => {
      console.log('chat websocket is connected')
      setIsSocketConnected(true)
    })
    socketConn.addEventListener('close', () => {
      console.log('chat Connection is closed')
      setIsSocketConnected(false)
    })
    socketConn.addEventListener('error', (e) =>
      console.error('chat socket Connection is in error', e)
    )
    socketConn.addEventListener('message', (e) => {
      const text = JSON.parse(e.data).message
      const newTxt = decodeUnicodeString(text)
      switch (text) {
        case StreamSpecialCase.RequestTimeOut:
          return
        case StreamSpecialCase.StreamFailed:
          setStreamStatus(StreamStatus.Ready)
          setStreamMessage(ErrorMessage.General)
          return
        case StreamSpecialCase.ProcessFailed:
          setStreamStatus(StreamStatus.Ready)
          setStreamMessage(ErrorMessage.General)
          return
        default:
          if (text === StreamSpecialCase.FinishStream) {
            setStreamStatus(StreamStatus.Ready)
            setIsFinished(true)
          } else {
            if (newTxt === 'Internal server error') {
              if (streamStatus === StreamStatus.Ready) {
                console.log('error')
                // setStreamMessage(
                //   '일시적인 오류로 인해 답변을 드릴 수 없습니다. 잠시 후 다시 시도해주세요.'
                // )
                // need to create new message
                setTimeout(() => {
                  setStreamStatus(StreamStatus.Ready)
                }, 2000)
              } else {
                setStreamStatus(StreamStatus.Ready)
              }
            } else if (newTxt && newTxt !== 'undefined') {
              setStreamStatus(StreamStatus.Streaming)
              setStreamMessage((prev: string) => prev + newTxt)
            }
          }
      }
    })
    setSocketConnection(socketConn)
  }

  const sendRequest = (question: string) => {
    if (document && socketConnection?.readyState === WebSocket.OPEN) {
      setIsFinished(false)
      setStreamStatus(StreamStatus.Waiting)
      setStreamMessage("")
      const payload = {
        routeKey: 'chat_module_v1',
        data: { question, version_id: versionID },
      }
      console.log('real sended!')
      socketConnection?.send(JSON.stringify(payload))
    }
  }

  useEffect(() => {
    if (isFinished && resultId && streamMessage) {
      try {
        supabase
          .from("result")
          .update({
            report: streamMessage
          }).eq("id", resultId)
        console.log('updated!')
      } catch {
        console.log('update failed')
      }
    }
  }, [isFinished, resultId, streamMessage])

  useEffect(() => {
    if (!isSocketConnected && resultId) {
      const init = async () => {
        await createChannel()
      }
      init()
    }
    return () => {
      if (isSocketConnected) socketConnection?.close()
    }
  }, [isSocketConnected, resultId])

  return {
    sendRequest,
    streamMessage,
    socketConnection,
    isSocketConnected,
    streamStatus,
  }
}

export default useMessage