export enum StreamStatus {
  Ready,
  Waiting,
  Streaming,
}

export enum StreamSpecialCase {
  RequestTimeOut = 'Endpoint request timed out',
  StreamFailed = 'lambda_stream_failed',
  ProcessFailed = 'lambda_process_failed',
  FinishStream = 'finish_stream',
}

export enum ErrorMessage {
  General = 'An error occurred. Please refresh the page and try again. If this problem persists, please contact us throught our messenger app on the bottom right.',
  Server = 'process_failed',
}

export enum LinqSocketEndpoint {
  Embed = 'embed',
  Summary = 'summary',
  Autoqa = 'autoqa',
  Chat = 'chat',
}

export type SocketValueProps<SendRequest> = {
  response: any
  streamStatus: StreamStatus
  sendRequest: SendRequest
  socketConnection?: WebSocket
}

export type LinqSocketValueProps = SocketValueProps<
  (routeKey: LinqSocketEndpoint, params: any) => void
>
