import { AtomEffect, atom } from "recoil";

const localStorageEffect: <T>(key: string) => AtomEffect<T> =
  (key: string) =>
  ({ setSelf, onSet, trigger }) => {
    const loadPersisted = () => {
      const savedValue =
        typeof window !== "undefined" ? localStorage.getItem(key) : undefined;
      if (!savedValue) return;
      setSelf(JSON.parse(savedValue as string));
    };

    if (trigger === "get") {
      loadPersisted();
    }

    // onSet -> Subscribe to changes in the atom value.
    onSet((newValue, oldValue, isReset) => {
      isReset
        ? localStorage.removeItem(key)
        : localStorage.setItem(key, JSON.stringify(newValue));
    });
  };

export const isAuthenticated = atom<boolean>({
  key: "isAuthenticated",
  default: false,
  effects_UNSTABLE: [localStorageEffect("is_authenticated")],
});
