import { useEffect } from "react";
import CheckUser from "../components/CheckUser";
import Layout from "../components/Layout";
import useAuth from "../hooks/useAuth";
import { useNavigate } from "react-router-dom";

function Login() {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/analyze");
    }
  }, [isAuthenticated]);

  return (
    <Layout
      title={`기업안전진단시스템`}
      sidebar={false}
      authenticatedPage={false}
    >
      <CheckUser />
    </Layout>
  );
}

export default Login;
