import { textToKo } from "../constants";

const InputField = ({
  register,
  fieldName,
  readOnly = false,
  placeholder,
  type = "text",
  list = false,
}: {
  register: any;
  fieldName: string;
  readOnly?: boolean;
  placeholder?: string;
  type?: string;
  list?: boolean;
}) => {
  return (
    <>
      <label
        htmlFor={fieldName}
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        {textToKo[fieldName]}
      </label>
      <div className="mt-2">
        <input
          required
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-main-600 sm:text-sm sm:leading-6"
          readOnly={readOnly}
          placeholder={placeholder}
          type={type}
          list={list ? fieldName : ""}
          {...register(fieldName)}
        />
      </div>
    </>
  );
};

const TextAreaField = ({
  register,
  fieldName,
  placeholder,
  readOnly = false,
}: {
  register: any;
  fieldName: string;
  placeholder?: string;
  readOnly?: boolean;
}) => {
  return (
    <>
      <label
        htmlFor={fieldName}
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        {textToKo[fieldName]}
      </label>
      <div className="mt-2">
        <textarea
          readOnly={readOnly}
          required
          rows={3}
          className="block w-full rounded-md border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-main-600 sm:text-sm sm:leading-6"
          placeholder={placeholder}
          {...register(fieldName)}
        />
      </div>
    </>
  );
};

const SelectField = ({
  register,
  fieldName,
  options,
  getValues,
  readOnly = false,
}: {
  register: any;
  fieldName: string;
  options: string[];
  getValues: any;
  readOnly?: boolean;
}) => {
  return (
    <>
      <label
        htmlFor={fieldName}
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        {textToKo[fieldName]}
      </label>
      <div className="mt-2">
        <select
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-main-600 sm:text-sm sm:leading-6"
          {...register(fieldName)}
        >
          {options.map((option) => (
            <option
              key={option}
              value={option}
              disabled={readOnly && getValues(fieldName) !== option}
            >
              {textToKo[option]}
            </option>
          ))}
        </select>
      </div>
    </>
  );
};

export { InputField, TextAreaField, SelectField };
