import { useCallback, useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { isAuthenticatedSelector } from "../selectors";

type UseAuthHooks = () => {
  authenticateUser: any;
  unAuthenticateUser: any;
  isAuthenticated: boolean;
};

const useAuth: UseAuthHooks = () => {
  const [isAuthenticated, setIsAuthenticated] = useRecoilState<boolean>(
    isAuthenticatedSelector
  );

  const authenticateUser = useCallback(async () => {
    setIsAuthenticated(true);
  }, []);

  const unAuthenticateUser = useCallback(async () => {
    setIsAuthenticated(false);
  }, []);

  return {
    authenticateUser,
    unAuthenticateUser,
    isAuthenticated,
  };
};

export default useAuth;
