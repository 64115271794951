import { textToKo } from "../constants";
import { InputPageProps } from "../interfaces";
import { TextAreaField } from "./inputs";

const attributes = ["방위", "이격거리 및 시설 종류"];
const cardinalDirections: any = {
  east: "동",
  west: "서",
  south: "남",
  north: "북",
};
const selectOptions = {
  setbackDistanceAndFacilityType: [
    "noFacilitiesWithin20m",
    "noFacilitiesWithin10m",
    "1OrMoreFactoriesWarehousesAndHazardousFacilitiesWithin10m",
    "2OrMoreFactoriesWarehousesAndHazardousFacilitiesWithin10m",
  ],
};

function Page7({ register, getValues, readOnly = false }: InputPageProps) {
  return (
    <div className="space-y-12">
      <div className="border-b border-gray-900/10 pb-12">
        <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          <div className="col-span-full">
            <label
              htmlFor="about"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              주변 환경
            </label>
            <div className="mt-2 flow-root">
              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead>
                      <tr className="divide-x divide-gray-200">
                        {attributes.map((attribute) => {
                          return (
                            <th
                              key={attribute}
                              scope="col"
                              className="py-3.5 px-4 text-left text-sm font-semibold text-gray-900"
                            >
                              {attribute}
                            </th>
                          );
                        })}
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {Object.keys(cardinalDirections).map((key) => (
                        <tr key={key} className="divide-x divide-gray-200">
                          <td className="whitespace-nowrap p-4 text-sm text-gray-900">
                            {cardinalDirections[key]}
                          </td>
                          <td className="whitespace-nowrap p-2 text-sm text-gray-900">
                            <select
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-main-600 sm:text-sm sm:leading-6"
                              {...register(
                                `azimuthalSurroundings.${key}.setbackDistance`
                              )}
                            >
                              {(selectOptions as any)[
                                "setbackDistanceAndFacilityType"
                              ].map((option: string) => (
                                <option
                                  key={option}
                                  value={option}
                                  disabled={
                                    readOnly &&
                                    getValues(
                                      `azimuthalSurroundings.${key}.setbackDistance`
                                    ) !== option
                                  }
                                >
                                  {textToKo[option]}
                                </option>
                              ))}
                            </select>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div className="col-span-full">
            <TextAreaField
              readOnly={readOnly}
              register={register}
              fieldName="plannedExpansionAndDemolition"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Page7;
