// @ts-nocheck

import { last, min, sortBy, round } from "lodash";
import { optionToScore } from "../constants";

const buildingLogic = (data: any) => {
  const { buildingInfo, industryBasicPoint } = data;
  const {
    columns,
    roof,
    exteriorWalls,
    aboveGroundFloors,
    buildingType,
    totalFloorArea,
  } = last(sortBy(buildingInfo, "totalFloorArea"));
  var result = 0;
  try {
    var constfloorLevel = null;
    var areaScoreLevel = null;
    var areaSize = null;

    const buildingStructureScore = min([
      optionToScore.columns[columns] +
        optionToScore.roof[roof] +
        optionToScore.exteriorWalls[exteriorWalls],
      1.1,
    ]); // 건물구조점수

    if (aboveGroundFloors == 1) {
      constfloorLevel = "singleFloor";
    }
    if (aboveGroundFloors > 1 && aboveGroundFloors <= 3) {
      constfloorLevel = "twoToThreeFloors";
    }
    if (aboveGroundFloors > 3 && aboveGroundFloors <= 10) {
      constfloorLevel = "fourToTenFloors";
    }
    if (aboveGroundFloors > 10) {
      constfloorLevel = "elevenOrMoreFloors";
    }

    if (industryBasicPoint >= 0.7 && industryBasicPoint <= 1.5) {
      areaScoreLevel = "level1";
    }
    if (industryBasicPoint > 1.5 && industryBasicPoint <= 2.0) {
      areaScoreLevel = "level2";
    }
    if (industryBasicPoint > 2.0 && industryBasicPoint <= 3.0) {
      areaScoreLevel = "level3";
    }
    if (industryBasicPoint > 3.0) {
      areaScoreLevel = "level4";
    }

    if (totalFloorArea <= 10000) {
      areaSize = "upToTenThousand";
    }
    if (totalFloorArea > 10000 && totalFloorArea <= 50000) {
      areaSize = "tenThousandToFiftyThousand";
    }
    if (totalFloorArea > 50000) {
      areaSize = "aboveFiftyThousand";
    }

    const numberOfFloorsScore = optionToScore[buildingType][constfloorLevel]; // 층수점수
    const areaScore = optionToScore[areaScoreLevel][areaSize]; // 면적점수

    result = round(buildingStructureScore * numberOfFloorsScore * areaScore, 2);
  } catch (error) {
    alert("[Error: Building] 문제가 발생했습니다. 관리자에게 문의해주세요.");
  }

  return result;
};

const industryLogic = (data: any) => {
  var result = 0;
  try {
    var wordCount = 0;
    const { industryBasicPoint, productionProcessDescription } = data;

    const minusWords = [
      "반응기",
      "가열",
      "집진",
      "건조",
      "고온",
      "용매",
      "연소",
      "가스",
    ];

    minusWords.map((word) => {
      if (productionProcessDescription.includes(word)) {
        wordCount += 1;
      }
    });
    const processSignificantRiskScore = 0.9 - 0.05 * wordCount;
    result = round(industryBasicPoint * processSignificantRiskScore, 2);
  } catch (error) {
    alert("[Error: Industry] 문제가 발생했습니다. 관리자에게 문의해주세요.");
  }

  return result;
};

const disasterLogic = (data: any) => {
  var result = 0;
  try {
    const {
      disasterPreparednessDedicatedStaff,
      fireDrillFrequency,
      safetyEducationFrequency,
      permitSystem,
      smokingManagementAtTheBusinessPremises,
    } = data;

    var fireDrillFrequencyToStandard = "none";

    if (fireDrillFrequency == 1) {
      fireDrillFrequencyToStandard = "annuallyOnce";
    }
    if (fireDrillFrequency == 2) {
      fireDrillFrequencyToStandard = "annuallyTwice";
    }
    if (fireDrillFrequency >= 3) {
      fireDrillFrequencyToStandard = "annuallyThreeOrMore";
    }

    var safetyEducationFrequencyToStandard = "none";

    if (safetyEducationFrequency == 1) {
      safetyEducationFrequencyToStandard = "annuallyOnce";
    }
    if (safetyEducationFrequency >= 2) {
      safetyEducationFrequencyToStandard = "annuallyTwiceOrMore";
    }

    const disasterPreparednessDedicatedStaffScore =
      optionToScore.disasterPreparednessDedicatedStaff[
        disasterPreparednessDedicatedStaff
      ];
    const fireDrillFrequencyScore =
      optionToScore.fireDrillFrequency[fireDrillFrequencyToStandard];
    const safetyEducationFrequencyScore =
      optionToScore.safetyEducationFrequency[
        safetyEducationFrequencyToStandard
      ];
    const permitSystemScore = optionToScore.permitSystem[permitSystem];
    const smokingManagementAtTheBusinessPremisesScore =
      optionToScore.smokingManagementAtTheBusinessPremises[
        smokingManagementAtTheBusinessPremises
      ];

    result = round(
      disasterPreparednessDedicatedStaffScore *
        fireDrillFrequencyScore *
        safetyEducationFrequencyScore *
        permitSystemScore *
        smokingManagementAtTheBusinessPremisesScore,
      2
    );
  } catch (error) {
    alert("[Error: Disaster] 문제가 발생했습니다. 관리자에게 문의해주세요.");
  }

  return result;
};

const fireLogic = (data: any) => {
  var result = 0;
  try {
    const {
      fireDetectionReceiverMonitoring,
      firePumpOperationMethod,
      firewaterSupplyTime,
      fireExtinguishers,
    } = data;

    const fireDetectionReceiverMonitoringScore =
      optionToScore.fireDetectionReceiverMonitoring[
        fireDetectionReceiverMonitoring
      ]; // 화재감지기 수신반 감시
    const firePumpOperationMethodScore =
      optionToScore.firePumpOperationMethod[firePumpOperationMethod]; // 소방펌프 기동 방식
    const firewaterSupplyTimeScore =
      optionToScore.firewaterSupplyTime[firewaterSupplyTime]; // 소방용수 확보 시간
    const fireExtinguishersScore =
      optionToScore.fireExtinguishers[fireExtinguishers]; // 소화기

    result = round(
      fireDetectionReceiverMonitoringScore +
        firePumpOperationMethodScore +
        firewaterSupplyTimeScore +
        fireExtinguishersScore,
      2
    );
  } catch (error) {
    alert("[Error: Fire] 문제가 발생했습니다. 관리자에게 문의해주세요.");
  }

  return result;
};

const nearbyFacilitiesLogic = (data: any) => {
  var result = 0;
  try {
    const { azimuthalSurroundings } = data;
    const { east, west, south, north } = azimuthalSurroundings;

    const eastScore =
      optionToScore.otherFireFacilityInstallationManagement[
        east.setbackDistance
      ];
    const westScore =
      optionToScore.otherFireFacilityInstallationManagement[
        west.setbackDistance
      ];
    const southScore =
      optionToScore.otherFireFacilityInstallationManagement[
        south.setbackDistance
      ];
    const northScore =
      optionToScore.otherFireFacilityInstallationManagement[
        north.setbackDistance
      ];

    result = round(eastScore + westScore + southScore + northScore, 2);
  } catch (error) {
    alert("[Error: Facilities] 문제가 발생했습니다. 관리자에게 문의해주세요.");
  }

  return result;
};

const classNames = (...classes: string[]) => {
  return classes.filter(Boolean).join(" ");
};

function decodeUnicodeString(encodedString: string): string {
  let decodedString = ''
  for (let i = 0; i < encodedString.length; i++) {
    decodedString += String.fromCodePoint(encodedString.charCodeAt(i))
  }
  return decodedString
}

export {
  buildingLogic,
  industryLogic,
  disasterLogic,
  fireLogic,
  nearbyFacilitiesLogic,
  classNames,
  decodeUnicodeString,
};
