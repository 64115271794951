import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { Page1, Page2, Page3, Page4, Page5, Page6, Page7 } from "../components";
import Layout from "../components/Layout";
import useMessage from "../hooks/useMessage";
import { StreamStatus } from "../interfaces/stream";

type BuildingInfo = {
  buildingName: string;
  yearBuilt: string;
  totalFloorArea: string;
  buildingHeight: string;
  aboveGroundFloors: string;
  belowGroundFloors: string;
  columns: string;
  roof: string;
  exteriorWalls: string;
  buildingType: string;
};

type AzimuthalSurroundings = {
  setbackDistance: string;
};

type TotalScore = {
  buildingScore: number;
  industryScore: number;
  disasterScore: number;
  fireScore: number;
  nearbyFacilitiesScore: number;
};

type ObjectData = {
  [key: string]: any;
  buildingInfo: BuildingInfo[];
  azimuthalSurroundings: { [direction: string]: AzimuthalSurroundings };
  totalScore: TotalScore;
};

function ResultDetail() {
  const location = useLocation();
  const queryParameters: any = new URLSearchParams(location.search);
  const [isDoneSetData, setIsDoneSetData] = useState<boolean>(false);
  const [result, setResult] = useState<any>({});
  const [results, setResults] = useState<
    { name: string; score: number; average: number }[]
  >([]);

  useEffect(() => {
    const resultData = queryParameters.get("d");

    if (!resultData) {
      alert("잘못된 경로입니다.");
    } else {
      const decodedJsonResult = JSON.parse(
        decodeURIComponent(atob(resultData.replace(" ", "+")))
      );
      setResult(decodedJsonResult);
      setResults([
        {
          name: "건물",
          score: decodedJsonResult.totalScore?.buildingScore || 0,
          average: 5,
        },
        {
          name: "공정",
          score: decodedJsonResult.totalScore?.industryScore || 0,
          average: 6,
        },
        {
          name: "소방시설",
          score: decodedJsonResult.totalScore?.fireScore || 0,
          average: 6,
        },
        {
          name: "방재관리",
          score: decodedJsonResult.totalScore?.disasterScore || 0,
          average: 5,
        },
        {
          name: "기타위험",
          score: decodedJsonResult.totalScore?.nearbyFacilitiesScore || 0,
          average: 4,
        },
      ]);
      reset(decodedJsonResult);
      setIsDoneSetData(true);
    }
  }, []);

  const {
    register,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm();

  const { sendRequest, streamMessage, streamStatus } = useMessage(result?.id);

  const translateKey = (key: string): string => {
    const translationMap: { [key: string]: string } = {
      east: "동쪽",
      west: "서쪽",
      south: "남쪽",
      north: "북쪽",
    };

    return translationMap[key] || key;
  };

  const translateValue = (value: any): string => {
    if (value === null) return "없음";
    return value.toString();
  };

  const translateDirection = (direction: string): string => {
    const directionMap: { [direction: string]: string } = {
      east: "동쪽",
      west: "서쪽",
      south: "남쪽",
      north: "북쪽",
    };

    return directionMap[direction] || direction;
  };

  const objectToKoreanText = (obj: ObjectData): string => {
    let text = "";

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        switch (key) {
          case "buildingInfo":
            text += "건물 정보:\n";
            // eslint-disable-next-line no-loop-func
            obj[key].forEach((building: any, index: number) => {
              text += `  건물 ${index + 1}:\n`;
              for (const buildingKey in building) {
                text += `    ${translateKey(buildingKey)}: ${translateValue(
                  building[buildingKey]
                )}\n`;
              }
            });
            break;
          case "azimuthalSurroundings":
            text += "주변 환경:\n";
            for (const direction in obj[key]) {
              text += `  ${translateDirection(direction)}: ${translateValue(
                obj[key][direction].setbackDistance
              )}\n`;
            }
            break;
          case "totalScore":
            text += "종합 점수:\n";
            for (const scoreKey in obj[key]) {
              text += `  ${translateKey(scoreKey)}: ${
                obj[key as any][scoreKey]
              }\n`;
            }
            break;
          default:
            text += `${translateKey(key)}: ${translateValue(obj[key])}\n`;
            break;
        }
      }
    }

    return text;
  };

  const handleClickGenerate = () => {
    sendRequest(objectToKoreanText(result));
  };

  return (
    <Layout title="안전진단 결과" currentStep={1}>
      <form onSubmit={() => console.log("there is no action")}>
        {isDoneSetData ? (
          <div className="space-y-12">
            <Page1
              register={register}
              setValue={setValue}
              getValues={getValues}
              readOnly
            />
            <Page2
              register={register}
              setValue={setValue}
              getValues={getValues}
              readOnly
            />
            <Page3 register={register} getValues={getValues} readOnly />
            <Page4 register={register} getValues={getValues} readOnly />
            <Page5 register={register} getValues={getValues} readOnly />
            <Page6 register={register} getValues={getValues} readOnly />
            <Page7 register={register} getValues={getValues} readOnly />
            {results.length > 0 && (
              <>
                <div className="flow-root">
                  <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                      <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-300">
                          <thead className="bg-gray-50">
                            <tr>
                              <th
                                scope="col"
                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                              >
                                구분
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                평가결과
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:pr-6"
                              >
                                평균
                              </th>
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-gray-200 bg-white">
                            {results.map((result) => (
                              <tr key={result.name}>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                  {result.name}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  {result.score}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:pr-6">
                                  {result.average}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="space-y-12 border-t border-gray-900/10 pt-12">
                  <div className="pb-12">
                    <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                      <div className="col-span-full">
                        <label
                          htmlFor="about"
                          className="block leading-6 text-gray-900 text-lg font-bold"
                        >
                          Risk 보고서
                        </label>
                        <div className="mt-2 flow-root">
                          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8 whitespace-pre-line">
                              {result?.report || streamMessage}{" "}
                              {streamStatus !== StreamStatus.Ready && (
                                <input className="cursor-blink" />
                              )}
                            </div>
                          </div>
                        </div>
                        {!result?.report && (
                          <button
                            type="button"
                            onClick={handleClickGenerate}
                            className="mt-6 rounded-md bg-main-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-main-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-main-600 disabled:opacity-70"
                            disabled={streamStatus !== StreamStatus.Ready}
                          >
                            AI로 작성하기
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        ) : (
          <p>잘못된 접근입니다.</p>
        )}
      </form>
    </Layout>
  );
}

export default ResultDetail;
