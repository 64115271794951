import { reject } from "lodash";
import { useEffect, useState } from "react";
import { textToKo } from "../constants";
import { InputField, TextAreaField } from "./inputs";
import { BuildingInfoType, InputPageProps } from "../interfaces";

const emptyBuilding = {
  buildingName: "",
  yearBuilt: "",
  totalFloorArea: "",
  buildingHeight: "",
  aboveGroundFloors: "",
  belowGroundFloors: "",
  columns: "reinforcedConcrete",
  roof: "reinforcedConcrete",
  exteriorWalls: "nonCombustibleMaterial",
  buildingType: "general",
} as BuildingInfoType;

const selectOptions = {
  buildingType: ["factory", "warehouse", "general"],
  columns: ["reinforcedConcrete", "fireproofSteelFrame", "steelFrame", "other"],
  roof: ["reinforcedConcrete", "nonCombustibleMaterial", "combustibleMaterial"],
  exteriorWalls: ["nonCombustibleMaterial", "combustibleMaterial"],
};

function Page2({
  register,
  setValue,
  getValues,
  readOnly = false,
}: InputPageProps<BuildingInfoType[]>) {
  const [buildings, setBuildings] = useState<BuildingInfoType[]>(
    getValues("buildingInfo") || [emptyBuilding]
  );

  const InputFields = ({
    buildingIndex,
    attributesIndex,
  }: {
    buildingIndex: number;
    attributesIndex: number;
  }) => {
    const targetAttribute = Object.keys(emptyBuilding)[attributesIndex];
    const isSelect = Object.keys(selectOptions).includes(targetAttribute);
    const isNumber = [
      "yearBuilt",
      "totalFloorArea",
      "buildingHeight",
      "aboveGroundFloors",
      "belowGroundFloors",
    ].includes(targetAttribute);

    return isSelect ? (
      <select
        required
        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-main-600 sm:max-w-xs sm:text-sm sm:leading-6"
        {...register(`buildingInfo.[${buildingIndex}].${targetAttribute}`)}
      >
        {(selectOptions as any)[targetAttribute].map((option: string) => {
          return (
            <option
              key={option}
              value={option}
              disabled={
                readOnly &&
                getValues(
                  `buildingInfo.[${buildingIndex}].${targetAttribute}`
                ) !== option
              }
            >
              {textToKo[option]}
            </option>
          );
        })}
      </select>
    ) : (
      <input
        readOnly={readOnly}
        required
        type={isNumber ? `number` : `text`}
        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-main-600 sm:text-sm sm:leading-6"
        {...register(`buildingInfo.[${buildingIndex}].${targetAttribute}`)}
      />
    );
  };

  return (
    <div className="space-y-12">
      <div className="border-b border-gray-900/10 pb-12">
        <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          <div className="sm:col-span-3">
            <InputField
              readOnly={readOnly}
              register={register}
              fieldName="landArea"
              type="number"
            />
          </div>
          <div className="sm:col-span-3">
            <InputField
              readOnly={readOnly}
              register={register}
              fieldName="floorArea"
              type="number"
            />
          </div>

          <div className="col-span-full">
            <div className="flex justify-between">
              <label
                htmlFor="about"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                건물 정보
              </label>
              {!readOnly && (
                <button
                  type="button"
                  onClick={() => {
                    setBuildings((prev) => [...prev, emptyBuilding]);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="w-7 h-7 text-main-400"
                  >
                    <path
                      fillRule="evenodd"
                      d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 9a.75.75 0 00-1.5 0v2.25H9a.75.75 0 000 1.5h2.25V15a.75.75 0 001.5 0v-2.25H15a.75.75 0 000-1.5h-2.25V9z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              )}
            </div>
            <div className="mt-2 flow-root">
              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <table className="min-w-full divide-y divide-gray-300 input-table">
                    <thead>
                      <tr className="divide-x divide-gray-200">
                        <th
                          key="#"
                          scope="col"
                          className="py-3 px-2 text-left text-sm font-semibold text-gray-900"
                        >
                          #
                        </th>
                        {Object.keys(emptyBuilding).map((attribute) => (
                          <th
                            key={attribute}
                            scope="col"
                            className="py-3 px-2 text-left text-sm font-semibold text-gray-900 min-w-[100px]"
                          >
                            {textToKo[attribute]}
                          </th>
                        ))}
                        <th
                          scope="col"
                          className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                        >
                          <span className="sr-only">Delete</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {buildings &&
                        buildings.map((building, buildingIndex) => (
                          <tr
                            key={buildingIndex}
                            className="divide-x divide-gray-200"
                          >
                            <td className="whitespace-nowrap p-3.5 text-sm text-gray-900">
                              {buildingIndex}
                            </td>
                            {Object.values(building).map(
                              (e, attributesIndex) => (
                                <td
                                  className="whitespace-nowrap p-2 text-sm text-gray-900"
                                  key={attributesIndex}
                                >
                                  <InputFields
                                    buildingIndex={buildingIndex}
                                    attributesIndex={attributesIndex}
                                  />
                                </td>
                              )
                            )}
                            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                              {!readOnly && (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  fill="currentColor"
                                  className="w-4 h-4 text-main-400 cursor-pointer"
                                  onClick={() => {
                                    const livedBuilding = reject(
                                      getValues("buildingInfo"),
                                      getValues("buildingInfo")[buildingIndex]
                                    );
                                    setBuildings(livedBuilding as any);
                                    setValue("buildingInfo", livedBuilding);
                                  }}
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M16.5 4.478v.227a48.816 48.816 0 013.878.512.75.75 0 11-.256 1.478l-.209-.035-1.005 13.07a3 3 0 01-2.991 2.77H8.084a3 3 0 01-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 01-.256-1.478A48.567 48.567 0 017.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 013.369 0c1.603.051 2.815 1.387 2.815 2.951zm-6.136-1.452a51.196 51.196 0 013.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 00-6 0v-.113c0-.794.609-1.428 1.364-1.452zm-.355 5.945a.75.75 0 10-1.5.058l.347 9a.75.75 0 101.499-.058l-.346-9zm5.48.058a.75.75 0 10-1.498-.058l-.347 9a.75.75 0 001.5.058l.345-9z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              )}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div className="col-span-full">
            <TextAreaField
              readOnly={readOnly}
              register={register}
              fieldName="buildingNotes"
              placeholder="건물 연결통로, 클린룸 설치, 복합구조 등에 대한 설명을 기재합니다."
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Page2;
