export const KAKAOMAP_KEY = "8930db2722e25282a15de4db154d0050";

export const USERS = [
  {
    name: "admin",
    password: "123qwe123",
  },
];

export const textToKo: { [index: string]: string } = {
  contractorName: "계약자명",
  password: "비밀번호",
  businessName: "사업자명",
  businessAddress: "사업장 주소",
  businessAddress2: "상세 주소",
  businessZipCode: "우편변호",
  landArea: "대지면적",
  floorArea: "연 면적",
  buildingInfo: "건물 정보",
  buildingName: "이름",
  yearBuilt: "신축연도",
  totalFloorArea: "연면적",
  buildingHeight: "건물높이",
  aboveGroundFloors: "지상층수",
  belowGroundFloors: "지하층수",
  columns: "기둥",
  roof: "지붕",
  exteriorWalls: "외벽",
  balconyAvailability: "반자여부",
  buildingType: "건물 타입",
  buildingNotes: "건물 특이사항",
  industry: "업종",
  productManufactured: "생산제품",
  productionProcessDescription: "생산공정설명",
  transformerDetails: "변압기 내역",
  emergencyGenerator: "비상 발전기",
  otherSupportFacilities: "기타 지원설비",
  fireDetectionInstallationAreaRatio: "화재감지기 설치 면적 비율",
  fireDetectionReceiverMonitoring: "화재감지기 수신반 감시",
  sprinklerInstallationAreaRatio: "스프링클러 설치 면적 비율",
  firePumpOperationMethod: "소방펌프 기동 방식",
  firewaterSupplyTime: "소방용수 확보 시간",
  fireExtinguishers: "소화기",
  otherFireFacilityInstallationManagement: "기타 소방시설 설치/관리",
  staffCount: "근무인원",
  nightShiftPersonnel: "야간상주인원",
  disasterPreparednessDedicatedStaff: "방재활동 전담 인원",
  securityStaffCount: "경비 인원",
  safetyEducationFrequency: "안전교육 실시 주기",
  fireDrillFrequency: "소방훈련 실시 주기",
  permitSystem: "작업허가제도",
  smokingManagementAtTheBusinessPremises: "사업장 흡연 관리",
  distanceToFireStation: "소방서와의 거리",
  azimuthalSurroundings: "방위별 주변 환경",
  setbackDistance: "이격거리",
  facilityType: "시설 종류",
  plannedExpansionAndDemolition: "계획 중인 증설 및 철거",
  reinforcedConcrete: "철근콘크리트",
  fireproofSteelFrame: "내화철골",
  steelFrame: "철골",
  other: "기타",
  nonCombustibleMaterial: "불연재",
  combustibleMaterial: "가연재",
  factory: "공장",
  warehouse: "창고",
  general: "일반",
  operating: "운영",
  nonOperating: "미운영",
  smokingAreaDesignatedInsideOutside: "건물 내/외부 지정 흡연장",
  smokingAreaDesignatedOutside: "건물 외부에 지정 흡연장",
  noSmokingAtBusinessPremises: "사업장 금연",
  none: "없음",
  concurrentDuty: "겸임",
  dedicatedPersonnel1: "전담자1인",
  teamUnit: "팀단위",
  noFireDetectors: "화재감지기가 없음",
  fireDetectorsWithNoStaffOnsite:
    "화재감지기 수신반이 있으나 상주하는 인력이 없음",
  fireDetectorsLocatedWhereStaffPresentButNoDedicatedPersonnel:
    "화재감지기 수신반이 인력이 상주하는 곳에 위치하지만 전담인원이 없음",
  fireDetectorsWithDedicatedPersonnel:
    "화재감지기 수신반을 담당하는 전담인력이 있음",
  noFireExtinguishers: "소화기 없음",
  fireExtinguishersUncheckedForOver3Months:
    "소화기가 있으나 점검한지 3달 이상 경과함",
  fireExtinguishersCheckedRegularly: "소화기는 주기적으로 점검",
  noFirePumps: "소방펌프 없음",
  firePumpsManuallyOperated: "소방펌프 수동기동",
  firePumpsAutomaticallyOperated: "소방펌프 자동기동",
  unknown: "알수 없음",
  legalRequirements20Minutes: "법적기준(20분)",
  "20MinutesToLessThan2Hours": "20분~2시간미만",
  "2HoursAndAbove": "2시간 이상",
  noFacilitiesWithin20m: "주변 20m 이내에 시설 없음",
  noFacilitiesWithin10m: "주변 10m이내에 시설 없음",
  "1OrMoreFactoriesWarehousesAndHazardousFacilitiesWithin10m":
    "주변 10m이내에 공장, 창고 및 위험시설이 1개있음",
  "2OrMoreFactoriesWarehousesAndHazardousFacilitiesWithin10m":
    "주변 10m이내에 공장, 창고 및 위험시설이 2개 이상 있음",
};

export const optionToScore = {
  columns: {
    reinforcedConcrete: 0.8,
    fireproofSteelFrame: 0.9,
    steelFrame: 1,
    other: 1.1,
  },
  roof: {
    reinforcedConcrete: 0,
    nonCombustibleMaterial: 0.05,
    combustibleMaterial: 0.1,
  },
  exteriorWalls: {
    nonCombustibleMaterial: 0,
    combustibleMaterial: 0.3,
  },
  factory: {
    singleFloor: 0.9,
    twoToThreeFloors: 1.05,
    fourToTenFloors: 1.15,
    elevenOrMoreFloors: 1.15,
  },
  warehouse: {
    singleFloor: 0.9,
    twoToThreeFloors: 1.1,
    fourToTenFloors: 1.15,
    elevenOrMoreFloors: 1.15,
  },
  general: {
    singleFloor: 0.9,
    twoToThreeFloors: 0.95,
    fourToTenFloors: 1.05,
    elevenOrMoreFloors: 1.15,
  },
  level1: {
    upToTenThousand: 1,
    tenThousandToFiftyThousand: 1.03,
    aboveFiftyThousand: 1.05,
  },
  level2: {
    upToTenThousand: 1,
    tenThousandToFiftyThousand: 1.05,
    aboveFiftyThousand: 1.1,
  },
  level3: {
    upToTenThousand: 1,
    tenThousandToFiftyThousand: 1.1,
    aboveFiftyThousand: 1.15,
  },
  level4: {
    upToTenThousand: 1,
    tenThousandToFiftyThousand: 1.1,
    aboveFiftyThousand: 1.2,
  },
  disasterPreparednessDedicatedStaff: {
    none: 1.15,
    concurrentDuty: 1.1,
    dedicatedPersonnel1: 1,
    teamUnit: 0.9,
  }, // 방재전담자인력
  fireDrillFrequency: {
    none: 1.15,
    annuallyOnce: 1.05,
    annuallyTwice: 1,
    annuallyThreeOrMore: 0.95,
  }, // 소방훈련주기
  safetyEducationFrequency: {
    none: 1.05,
    annuallyOnce: 1,
    annuallyTwiceOrMore: 0.95,
  }, // 안전교육주기
  permitSystem: {
    nonOperating: 1.05,
    operating: 0.95,
  }, // 작업허가제도
  smokingManagementAtTheBusinessPremises: {
    nonOperating: 1.1,
    smokingAreaDesignatedInsideOutside: 1.05,
    smokingAreaDesignatedOutside: 1,
    noSmokingAtBusinessPremises: 0.95,
  }, // 흡연통제
  fireDetectionReceiverMonitoring: {
    noFireDetectors: 1.05,
    fireDetectorsWithNoStaffOnsite: 1,
    fireDetectorsLocatedWhereStaffPresentButNoDedicatedPersonnel: 0.95,
    fireDetectorsWithDedicatedPersonnel: 0.9,
  }, // 화재감지기 수신반 감시
  firePumpOperationMethod: {
    noFirePumps: 1.05,
    firePumpsManuallyOperated: 1,
    firePumpsAutomaticallyOperated: 0.95,
  }, // 소방펌프 기동 방식
  firewaterSupplyTime: {
    unknown: 1.05,
    legalRequirements20Minutes: 1,
    "20MinutesToLessThan2Hours": 0.95,
    "2HoursAndAbove": 0.9,
  }, // 소방용수 확보 시간
  fireExtinguishers: {
    noFireExtinguishers: 1.05,
    fireExtinguishersUncheckedForOver3Months: 1,
    fireExtinguishersCheckedRegularly: 0.95,
  }, // 소화기
  otherFireFacilityInstallationManagement: {
    noFacilitiesWithin20m: 0.9,
    noFacilitiesWithin10m: 0.95,
    "1OrMoreFactoriesWarehousesAndHazardousFacilitiesWithin10m": 1,
    "2OrMoreFactoriesWarehousesAndHazardousFacilitiesWithin10m": 1.1,
  }, // 기타 소방시설 설치/관리
};

export const dummyData = {
  businessName: "사업자명",
  businessAddress: "경기 수원시 장안구 덕영대로639번길 70",
  businessAddress2: "204 ",
  businessZipCode: "16318",
  landArea: "10000",
  floorArea: "10000",
  buildingInfo: [
    {
      buildingName: "test1",
      yearBuilt: "2019",
      totalFloorArea: "10000",
      buildingHeight: "100",
      aboveGroundFloors: "3",
      belowGroundFloors: "1",
      columns: "fireproofSteelFrame",
      roof: "reinforcedConcrete",
      exteriorWalls: "nonCombustibleMaterial",
      buildingType: "factory",
    },
    {
      buildingName: "test1",
      yearBuilt: "2020",
      totalFloorArea: "10000",
      buildingHeight: "100",
      aboveGroundFloors: "6",
      belowGroundFloors: "1",
      columns: "reinforcedConcrete",
      roof: "nonCombustibleMaterial",
      exteriorWalls: "combustibleMaterial",
      buildingType: "warehouse",
    },
    {
      buildingName: "test1",
      yearBuilt: "1996",
      totalFloorArea: "14000",
      buildingHeight: "100",
      aboveGroundFloors: "1",
      belowGroundFloors: "1",
      columns: "fireproofSteelFrame",
      roof: "reinforcedConcrete",
      exteriorWalls: "combustibleMaterial",
      buildingType: "general",
    },
  ],
  buildingNotes: "반응기, 가열",
  industry: "3",
  productManufactured: "ㄴㅇㄹㄴㅇㄹ",
  productionProcessDescription: "반응기, 가열",
  transformerDetails: "변압기",
  emergencyGenerator: "발전기",
  otherSupportFacilities: "지원설비",
  fireDetectionInstallationAreaRatio: "12",
  fireDetectionReceiverMonitoring: "noFireDetectors",
  sprinklerInstallationAreaRatio: "6",
  firePumpOperationMethod: "noFirePumps",
  firewaterSupplyTime: "unknown",
  fireExtinguishers: "noFireExtinguishers",
  staffCount: "12",
  nightShiftPersonnel: "12",
  disasterPreparednessDedicatedStaff: "none",
  securityStaffCount: "12",
  safetyEducationFrequency: "2",
  fireDrillFrequency: "4",
  permitSystem: "operating",
  smokingManagementAtTheBusinessPremises: "nonOperating",
  distanceToFireStation: "1",
  azimuthalSurroundings: {
    east: {
      setbackDistance: "noFacilitiesWithin20m",
    },
    west: {
      setbackDistance:
        "1OrMoreFactoriesWarehousesAndHazardousFacilitiesWithin10m",
    },
    south: {
      setbackDistance: "noFacilitiesWithin10m",
    },
    north: {
      setbackDistance:
        "2OrMoreFactoriesWarehousesAndHazardousFacilitiesWithin10m",
    },
  },
  plannedExpansionAndDemolition: "12",
  report: ""
};
