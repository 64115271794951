import { InputPageProps } from "../interfaces";
import { InputField, SelectField, TextAreaField } from "./inputs";

const selectOptions = {
  fireDetectionReceiverMonitoring: [
    "fireDetectorsWithDedicatedPersonnel",
    "fireDetectorsLocatedWhereStaffPresentButNoDedicatedPersonnel",
    "fireDetectorsWithNoStaffOnsite",
    "noFireDetectors",
  ],
  firePumpOperationMethod: [
    "firePumpsAutomaticallyOperated",
    "firePumpsManuallyOperated",
    "noFirePumps",
  ],
  firewaterSupplyTime: [
    "2HoursAndAbove",
    "20MinutesToLessThan2Hours",
    "legalRequirements20Minutes",
    "unknown",
  ],
  fireExtinguishers: [
    "fireExtinguishersCheckedRegularly",
    "fireExtinguishersUncheckedForOver3Months",
    "noFireExtinguishers",
  ],
};

function Page5({ register, getValues, readOnly = false }: InputPageProps) {
  return (
    <div className="space-y-12">
      <div className="border-b border-gray-900/10 pb-12">
        <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          <div className="sm:col-span-3">
            <InputField
              readOnly={readOnly}
              register={register}
              fieldName="fireDetectionInstallationAreaRatio"
              placeholder="단위: %"
              type="number"
            />
          </div>
          <div className="sm:col-span-3">
            <SelectField
              readOnly={readOnly}
              register={register}
              getValues={getValues}
              fieldName="fireDetectionReceiverMonitoring"
              options={selectOptions["fireDetectionReceiverMonitoring"]}
            />
          </div>
          <div className="sm:col-span-3">
            <InputField
              readOnly={readOnly}
              register={register}
              fieldName="sprinklerInstallationAreaRatio"
              placeholder="단위: %"
              type="number"
            />
          </div>
          <div className="sm:col-span-3">
            <SelectField
              readOnly={readOnly}
              register={register}
              getValues={getValues}
              fieldName="firePumpOperationMethod"
              options={selectOptions["firePumpOperationMethod"]}
            />
          </div>
          <div className="sm:col-span-3">
            <SelectField
              readOnly={readOnly}
              register={register}
              getValues={getValues}
              fieldName="firewaterSupplyTime"
              options={selectOptions["firewaterSupplyTime"]}
            />
          </div>
          <div className="sm:col-span-3">
            <SelectField
              readOnly={readOnly}
              register={register}
              getValues={getValues}
              fieldName="fireExtinguishers"
              options={selectOptions["fireExtinguishers"]}
            />
          </div>
          <div className="col-span-full">
            <TextAreaField
              readOnly={readOnly}
              register={register}
              fieldName="otherFireFacilityInstallationManagement"
              placeholder="종류 x 용량 x 개수"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Page5;
