import { Dialog, Transition } from "@headlessui/react";
import {
  ArrowLeftOnRectangleIcon,
  Bars3Icon,
  CircleStackIcon,
  PencilSquareIcon,
  ShieldCheckIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Fragment, useState } from "react";
import { classNames } from "../utils";
import NoPage from "../pages/NoPage";
import useAuth from "../hooks/useAuth";

interface Props {
  children: React.ReactNode;
  currentStep?: number;
  title?: string;
  sidebar?: boolean;
  authenticatedPage?: boolean;
}

export default function Layout({
  children,
  currentStep = 0,
  title,
  sidebar = true,
  authenticatedPage = true,
}: Props) {
  const navigate = useNavigate();
  const { isAuthenticated, unAuthenticateUser } = useAuth();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { pathname } = useLocation();

  const navigation = [
    {
      name: "새 정보입력",
      href: "/analyze",
      icon: PencilSquareIcon,
      activePath: ["/analyze", "/result"],
    },
    {
      name: "입력정보 조회",
      href: "/results",
      icon: CircleStackIcon,
      activePath: ["/results", "/results/show"],
    },
  ];

  return (
    <>
      {sidebar && isAuthenticated && (
        <div>
          <Transition.Root show={sidebarOpen} as={Fragment}>
            <Dialog
              as="div"
              className="relative z-50 lg:hidden"
              onClose={setSidebarOpen}
            >
              <Transition.Child
                as={Fragment}
                enter="transition-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-900/80" />
              </Transition.Child>

              <div className="fixed inset-0 flex">
                <Transition.Child
                  as={Fragment}
                  enter="transition ease-in-out duration-300 transform"
                  enterFrom="-translate-x-full"
                  enterTo="translate-x-0"
                  leave="transition ease-in-out duration-300 transform"
                  leaveFrom="translate-x-0"
                  leaveTo="-translate-x-full"
                >
                  <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-in-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in-out duration-300"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                        <button
                          type="button"
                          className="-m-2.5 p-2.5"
                          onClick={() => setSidebarOpen(false)}
                        >
                          <span className="sr-only">Close sidebar</span>
                          <XMarkIcon
                            className="h-6 w-6 text-white"
                            aria-hidden="true"
                          />
                        </button>
                      </div>
                    </Transition.Child>
                    {/* Sidebar component, swap this element with another sidebar if you like */}
                    <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-main-600 px-6 pb-2">
                      <div className="flex h-16 shrink-0 items-center">
                        <ShieldCheckIcon className="h-8 w-auto text-white" />
                      </div>
                      <nav className="flex flex-1 flex-col">
                        <ul
                          role="list"
                          className="flex flex-1 flex-col gap-y-7"
                        >
                          <li>
                            <ul role="list" className="-mx-2 space-y-1">
                              {navigation.map((item) => (
                                <li key={item.name}>
                                  <Link
                                    to={item.href}
                                    className={classNames(
                                      item.activePath.includes(pathname)
                                        ? "bg-main-700 text-white"
                                        : "text-main-200 hover:text-white hover:bg-main-700",
                                      "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                    )}
                                  >
                                    <item.icon
                                      className={classNames(
                                        item.activePath.includes(pathname)
                                          ? "text-white"
                                          : "text-main-200 group-hover:text-white",
                                        "h-6 w-6 shrink-0"
                                      )}
                                      aria-hidden="true"
                                    />
                                    {item.name}
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition.Root>

          {/* Static sidebar for desktop */}
          <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-64 lg:flex-col">
            {/* Sidebar component, swap this element with another sidebar if you like */}
            <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-main-600 px-6">
              <div className="flex h-16 shrink-0 items-center text-white font-bold">
                <ShieldCheckIcon className="h-8 w-auto mr-2" />
                <span>기업안전진단 시스템</span>
              </div>
              <nav className="flex flex-1 flex-col">
                <ul role="list" className="flex flex-1 flex-col gap-y-7">
                  <li>
                    <ul role="list" className="-mx-2 space-y-1">
                      {navigation.map((item) => (
                        <li key={item.name}>
                          <Link
                            to={item.href}
                            className={classNames(
                              item.activePath.includes(pathname)
                                ? "bg-main-700 text-white"
                                : "text-main-200 hover:text-white hover:bg-main-700",
                              "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                            )}
                          >
                            <item.icon
                              className={classNames(
                                item.activePath.includes(pathname)
                                  ? "text-white"
                                  : "text-main-200 group-hover:text-white",
                                "h-6 w-6 shrink-0"
                              )}
                              aria-hidden="true"
                            />
                            {item.name}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </li>
                  <li className="-mx-6 mt-auto">
                    <button
                      onClick={() => {
                        unAuthenticateUser();
                        navigate("/");
                      }}
                      className="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-white hover:bg-gray-800 w-full"
                    >
                      <ArrowLeftOnRectangleIcon className="h-6 w-auto" />
                      <span className="sr-only">로그아웃</span>
                      <span aria-hidden="true">로그아웃</span>
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
          <div className="fixed w-full justify-between top-0 z-40 flex items-center gap-x-6 bg-main-600 px-4 py-4 shadow-sm sm:px-6 lg:hidden">
            <button
              type="button"
              className="-m-2.5 p-2.5 text-main-200 lg:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
            <div className="flex shrink-0 items-center text-white font-bold">
              <ShieldCheckIcon className="h-6 w-auto mr-2" />
              <span>기업안전진단 시스템</span>
            </div>
          </div>
        </div>
      )}
      {authenticatedPage && !isAuthenticated ? (
        <NoPage />
      ) : (
        <main
          className={`flex min-h-full flex-1 flex-col justify-center py-12 px-6 sm:px-6 lg:px-8 ${
            sidebar ? "lg:ml-64" : ""
          }`}
        >
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <h2 className="mt-6 text-center text-2xl font-semibold leading-9 tracking-tight text-gray-900">
              {title}
            </h2>
          </div>

          <div
            className={`mt-10 sm:mx-auto sm:w-full ${
              currentStep === 0 ? "sm:max-w-[480px]" : "sm:max-w-4xl"
            }`}
          >
            <div className="bg-white px-6 py-12 shadow rounded-md sm:rounded-lg sm:px-12">
              {children}
            </div>
          </div>
        </main>
      )}
    </>
  );
}
