import { InputPageProps } from "../interfaces";
import { InputField, TextAreaField } from "./inputs";

function Page4({ register, getValues, readOnly = false }: InputPageProps) {
  return (
    <div className="space-y-12">
      <div className="border-b border-gray-900/10 pb-12">
        <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          <div className="sm:col-span-3">
            <InputField
              readOnly={readOnly}
              register={register}
              fieldName="transformerDetails"
              placeholder="용량 x 대수 x Type"
            />
          </div>
          <div className="sm:col-span-3">
            <InputField
              readOnly={readOnly}
              register={register}
              fieldName="emergencyGenerator"
              placeholder="용량 x 대수 x Type"
            />
          </div>
          <div className="col-span-full">
            <TextAreaField
              readOnly={readOnly}
              register={register}
              fieldName="otherSupportFacilities"
              placeholder="종류 x 용량 x 대수"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Page4;
