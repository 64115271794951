import { selector } from "recoil";
import { isAuthenticated } from "../atoms";

export const isAuthenticatedSelector = selector({
  key: "isAuthenticatedSelector",
  get: ({ get }) => get(isAuthenticated),
  set: ({ set }, newisAuthenticated) => {
    set(isAuthenticated, newisAuthenticated);
  },
});
