import { format } from "date-fns";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Layout from "../components/Layout";
import { supabase } from "../supabaseClient";
import { filter } from "lodash";
import Swal from "sweetalert2";

function ResultList() {
  const [results, setResults] = useState<any>([]);

  const generateDetailUrl = (result: any) => {
    const encodedResult = btoa(encodeURIComponent(JSON.stringify(result)));
    return `/results/show?d=${encodedResult}`;
  };

  async function getResults() {
    const { data } = await supabase
      .from("result")
      .select()
      .order("createdAt", { ascending: false });
    setResults(data);
  }

  async function delResult(id: any) {
    Swal.fire({
      icon: "warning",
      text: "정맑로 삭제하시겠습니까?",
      heightAuto: false,
      showCancelButton: true,
      confirmButtonColor: "rgb(0, 139, 255)",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const { error } = await supabase.from("result").delete().eq("id", id);
        if (error) {
          alert(
            "[Error: DB Del] 문제가 발생했습니다. 관리자에게 문의해주세요."
          );
        } else {
          setResults(filter(results, (e) => e.id !== id));
        }
      }
    });
  }

  useEffect(() => {
    getResults();
  }, []);

  return (
    <Layout title="결과 리스트">
      <div className="space-y-6">
        <div className="flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                {results.length > 0 && (
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                          사업자명
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:pr-6"
                        >
                          등록일시
                        </th>
                        <th
                          scope="col"
                          className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                        >
                          <span className="sr-only">Delete</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {results.map((result: any) => (
                        <tr key={result.id}>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                            <Link
                              to={generateDetailUrl(result)}
                              className="text-main-600 hover:text-main-900"
                            >
                              {result.businessName}
                              <span className="sr-only">
                                , {result.businessName}
                              </span>
                            </Link>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:pr-6">
                            {format(
                              new Date(result.createdAt),
                              "yyyy-MM-dd, H:mm"
                            )}
                          </td>
                          <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              fill="currentColor"
                              className="w-4 h-4 text-main-400 cursor-pointer"
                              onClick={() => {
                                delResult(result.id);
                              }}
                            >
                              <path
                                fillRule="evenodd"
                                d="M16.5 4.478v.227a48.816 48.816 0 013.878.512.75.75 0 11-.256 1.478l-.209-.035-1.005 13.07a3 3 0 01-2.991 2.77H8.084a3 3 0 01-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 01-.256-1.478A48.567 48.567 0 017.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 013.369 0c1.603.051 2.815 1.387 2.815 2.951zm-6.136-1.452a51.196 51.196 0 013.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 00-6 0v-.113c0-.794.609-1.428 1.364-1.452zm-.355 5.945a.75.75 0 10-1.5.058l.347 9a.75.75 0 101.499-.058l-.346-9zm5.48.058a.75.75 0 10-1.498-.058l-.347 9a.75.75 0 001.5.058l.345-9z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default ResultList;
