import Swal from "sweetalert2";
import { LoginFormData } from "../interfaces";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { find, includes } from "lodash";
import { USERS } from "../constants";
import { useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";

function CheckUser() {
  const navigate = useNavigate();
  const { authenticateUser } = useAuth();
  const [errorMessage, setErrorMessage] = useState<string>("");

  const {
    setValue,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<LoginFormData>();

  const onSubmit = (data: any) => {
    const currentUser = find(USERS, data);
    if (currentUser) {
      authenticateUser();
      navigate("/analyze");
    } else {
      setErrorMessage("인증되지 않은 계정입니다.");
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
    }
  };

  return (
    <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
      <div>
        <label
          htmlFor="name"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          계약자명
        </label>
        <div className="mt-2">
          <input
            required
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-main-600 sm:text-sm sm:leading-6"
            {...register("name")}
          />
          {errorMessage && (
            <p className="ml-2 block text-sm font-medium leading-6 text-red-400 text-end -mb-6">
              {errorMessage}
            </p>
          )}
        </div>
      </div>

      <div>
        <label
          htmlFor="password"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          비밀번호
        </label>
        <div className="mt-2">
          <input
            type="password"
            required
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-main-600 sm:text-sm sm:leading-6"
            {...register("password")}
          />
        </div>
      </div>
      <div>
        <button
          type="submit"
          className="flex w-full justify-center rounded-md bg-main-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-main-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-main-600"
        >
          시작
        </button>
      </div>
    </form>
  );
}

export default CheckUser;
